<template>
  <div class="add-quota">
    <div class="content-wrapper" @click="createQuota">
      <div class="content">
        <v-btn
          class="mx-2"
          fab
          dark
          outlined
          large
          color="primary"
          @click.stop="createQuota"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
        <div class="content__text">Créer un nouveau devis</div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { QuotaService } from "@/services/quotas.service.js";
export default {
  name: "AddQuota",
  inject: ["projectId"],
  props: {
    quotasQuantity: {
      type: Number,
      require: true
    }
  },
  methods: {
    createQuota() {
      QuotaService.createQuota({
        name: `Mon projet de rénovation ${this.quotasQuantity + 1}`,
        project_id: this.projectId(),
        edit: true,
        custom_status: 1,
        create_date: format(new Date(), 'yyyy-MM-dd')
      }).then(({ data }) => {
        this.$router.push({
          name: "RoomId",
          params: {
            roomId: "new",
          },
          query: {
            project_id: this.projectId(),
            quota_id: data.id,
          },
        });
      });
    },
  },
};
</script>